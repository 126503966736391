<template>
  <div v-if="rendersCookieAcceptNav" class="csn-coockie-accept-wrapper">
    <div class="csn-coockie-accept">
      <span>{{ t('text_1') }}</span>
      <span>{{ t('text_2') }}</span>
      <span>{{ t('text_3') }}</span>
      <span>{{ t('text_4') }}</span>

      <Route
        class="csn-coockie-accept-router-link"
        :to="{ name: route.SUPPORT, params: { slug: 'privacy' } }"
      >
        {{ t('privacy') }}
      </Route>
      <span>&#38;</span>
      <Route
        class="csn-coockie-accept-router-link"
        :to="{ name: route.SUPPORT, params: { slug: 'cookie_policy' } }"
      >
        {{ t('cookie_policy') }}
      </Route>
      <div class="csn-coockie-accept-button-container">
        <button
          class="btn casino-btn csn-coockie-accept-button"
          @click="handleButtonClick"
        >
          <span>{{ t('button_label') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  Module,
  ARE_COOKIES_ACCEPTED,
  SET_ARE_COOKIES_ACCEPTED,
  RouteName,
  ACCEPT_COOKIES,
  COOKIE_ACCEPT,
} from '@/constants'

export default {
  name: COOKIE_ACCEPT,
  components: {
    Route: () => import('@/components/Route'),
  },
  computed: {
    ...mapState(Module.MAIN, [ARE_COOKIES_ACCEPTED]),
    route: () => ({
      SUPPORT: RouteName.SUPPORT,
    }),
    rendersCookieAcceptNav() {
      return !this.ARE_COOKIES_ACCEPTED
    },
    t() {
      return this.$createComponentTranslator(COOKIE_ACCEPT)
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [SET_ARE_COOKIES_ACCEPTED]),
    handleButtonClick() {
      this.SET_ARE_COOKIES_ACCEPTED(true)
      localStorage.setItem(ACCEPT_COOKIES, true)
    },
  },
}
</script>
